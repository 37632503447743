@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}.zola-loader {
  padding-top: 25px;
  margin: 0 auto;
  height: 125px;
  width: 20px;
  position: relative;
}
@media (min-width: 992px) {
  .zola-loader {
    padding-top: 200px;
  }
  .modernizr-cssanimations .zola-loader {
    height: 325px;
  }
}
.zola-loader .petal {
  position: absolute;
  width: 20px;
  height: 60px;
  background-size: auto 30px;
  background-repeat: no-repeat;
  animation: spin 1.5s infinite ease;
}
.zola-loader .petal:nth-of-type(2) {
  animation-delay: 0.1s;
}
.zola-loader .petal:nth-of-type(3) {
  animation-delay: 0.2s;
}
.zola-loader .petal:nth-of-type(4) {
  animation-delay: 0.3s;
}
.zola-loader .petal:nth-of-type(5) {
  animation-delay: 0.4s;
}
.zola-loader .petal:nth-of-type(6) {
  animation-delay: 0.5s;
}
.zola-loader .petal:nth-of-type(7) {
  animation-delay: 0.6s;
}
.zola-loader .petal:nth-of-type(8) {
  animation-delay: 0.7s;
}
.zola-loader .petal:nth-of-type(4n + 1) {
  background-image: url('../../../../assets/images/spinners/petal1.svg');
}
.zola-loader .petal:nth-of-type(4n + 2) {
  background-image: url('../../../../assets/images/spinners/petal2.svg');
}
.zola-loader .petal:nth-of-type(4n + 3) {
  background-image: url('../../../../assets/images/spinners/petal3.svg');
}
.zola-loader .petal:nth-of-type(4n + 4) {
  background-image: url('../../../../assets/images/spinners/petal4.svg');
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.modernizr-no-cssanimations .zola-loader {
  margin: auto;
  width: 50px;
  height: 50px;
  background-image: url('../../../../assets/images/spinners/dark.gif');
}
